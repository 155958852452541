const Element: React.FC<{name: string, am: number, an: number, symbol: string}> = (props) => {
    const { name, am, an, symbol} = props;
    return (
        <>
            <svg viewBox="0 0 200 200" filter="drop-shadow( 6px 6px 6px rgba(0, 0, 0, 0.9))">
                  <g transform="translate(50, 50)">
                  <rect x={0} y={0} width={100} height={100} rx={14} fill={"#ffffff"} filter={"drop"}/>
                  <text x={5} y={90} fontFamily="Verdana" fontSize={7} fill="black">{name}</text>
                  <text x={4} y={15} fontFamily="Verdana" fontSize={3} fontWeight={'bold'} fill="black">Atomic Number: {an}</text>
                  <text x={60} y={15} fontFamily="Verdana" fontSize={3} fontWeight={'bold'} fill="black">Atomic Mass: {am}</text>
                  <text x="25%" y="25%" dominantBaseline="middle" textAnchor="middle" fontFamily="Verdana" fontSize={40} fontWeight={540} fill="black">{symbol}</text>
                  </g>
                </svg>
        </>
    )
}

export default Element;