import { RadialGradient} from '@visx/gradient';
import Element from './Element';
import el from '../models/element';
import SearchAppBar from './SearchAppBar';
import { useState } from 'react';
export default function ElementScreen() {

  const [isElementRendered, setIsElementRendered]= useState(false)
  const [selectedElement, setSelectedElement] = useState(new el('', -1, -1, ''))
  const handleInput = (option: el, rendered: boolean) => {
    setIsElementRendered(rendered);
    setSelectedElement(option)
  }

  return (
           <div className="relative">
            <SearchAppBar handleInput={handleInput}/>
            <svg
              width={window.innerWidth}
              height={window.innerHeight}
            ><g>
              <RadialGradient id={'appBackground'} from="#c5c9c9" to="#fffff" r="60%" />
              <rect width={window.innerWidth} height={window.innerWidth} fill={"url(#appBackground)"} />
                {isElementRendered && <Element key= {selectedElement.id} name={ selectedElement.name} am={selectedElement.atomicMass} an={selectedElement.atomicNumber } symbol={selectedElement.symbol}/>}
            </g>
            </svg>
          </div>
  );
}
