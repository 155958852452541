import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
// import SearchIcon from '@mui/icons-material/Search';
import { Autocomplete, Button, createTheme, TextField, ThemeProvider } from '@mui/material';
import ElementsData from './ElementsData.json';
import Element from '../models/element';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

// const SearchIconWrapper = styled('div')(({ theme }) => ({
//   padding: theme.spacing(0, 2),
//   height: '100%',
//   position: 'absolute',
//   pointerEvents: 'none',
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',
// }));

const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#1976d2',
      },
    },
  });

type ButtonProps = {
    handleInput: (option: Element, rendered: boolean) => void;
  };

export default function SearchAppBar({handleInput}: ButtonProps) {
  return (
    <Box sx={{ flexGrow: 1 }}>
         <ThemeProvider theme={darkTheme}>
      <AppBar position="static" color='primary'>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
          >
            ELEMENTA
          </Typography>
          <Search>
            {/* <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper> */}
            <Autocomplete
            disablePortal
            id="combo-box-demo"
            getOptionLabel={(ElementsData) => ElementsData.name}
            options={ElementsData}
            sx={{ width: 300 }}
            renderInput={(params) =><TextField {...params} placeholder="Search Elements..." color='primary'/>}
            isOptionEqualToValue={(option, value) => option.name === value.name }
            renderOption={(props, option, { selected }) => (
                <li {...props}>
                   <Button key={option.name} id={option.name} onClick={() => handleInput(new Element(option.name, option.atomicNumber, option.atomicMass, option.symbol), true)}>{option.name}</Button>
                </li>)}
            />
          </Search>
        </Toolbar>
      </AppBar>
      </ThemeProvider>
    </Box>
  );
}