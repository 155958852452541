class Element{
    id: string;
    atomicNumber: number;
    atomicMass: number;
    name: string;
    symbol: string;
    constructor(name: string, an: number, am: number, symbol: string){
        this.name =name;
        this.atomicNumber = an;
        this.atomicMass = am;
        this.id = name;
        this.symbol = symbol;
    }
}

export default Element;