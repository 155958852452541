import './App.css';
import ElementScreen from './components/ElementScreen';

function App() {
  return (
    <div className="App">
      <ElementScreen/>
    </div>
  );
}

export default App;
